<template>
  <div>
    <Header :has-login="hasLogin" :username="user.username"/>

    <div class="m-content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>

        <el-form-item label="内容" prop="content">
          <mavon-editor v-model="ruleForm.content"
                        ref=md @imgAdd="imgAdd"
                        @imgDel="imgDel">
          </mavon-editor>
        </el-form-item>

        <el-form-item label="相关人" prop="relevantInfo">
        <el-select v-model="ruleForm.relevantInfo" filterable placeholder="请选择" multiple value-key="id">
          <el-option
              v-for="item in allUserInfo"
              :key="item.id"
              :label="item.username"
              :value="item">
          </el-option>
        </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import Header from "../components/Header";
export default {
  name: "DiaryEdit",
  components: {Header},
  data() {
    return {
      ruleForm: {
        id:'',
        userId: '',
        title: '',
        relevantInfo: {},
        content: '',
      },
      value: {},
      user: {
        id: '',
        username: '',
        avatar: '',
        email: ""
      },
      allUserInfo: [],
      hasLogin: false,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 3, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
          console.log(this.allUserInfo)

          this.$axios.post('/diary', this.ruleForm, {
            headers: {
              "Authorization": localStorage.getItem("token")
            }
          }).then(res => {
            console.log(res)

            this.$alert('操作成功', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.$router.push("/diary")
              }
            })
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //这里的imgAdd和DelAdd是mavon-editor的固定方法
    imgAdd(pos, $file){
      // let _this = this;
      // 第一步.将图片上传到服务器.
      let formdata = new FormData();
      formdata.append('fileName', $file);
      this.$axios.post("http://localhost:1234/uploadFile", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        console.log(res)
        this.$refs.md.$img2Url(pos, res.data.url)

      });
    },
    imgDel(pos){

    },
  },
  created() {
    const diaryId = this.$route.params.diaryId
    console.log(diaryId)
    if (diaryId) {
      this.$axios.get('/diary/' + diaryId,
          {
            headers: {
              "Authorization": localStorage.getItem("token")
            }
          }).then(res => {
        const diary = res.data.data
        this.ruleForm.id = diary.id
        this.ruleForm.userId = diary.userId
        this.ruleForm.title = diary.title
        this.ruleForm.content = diary.content
        this.ruleForm.relevantInfo = diary.relevantInfo
      })
    }
    if (this.$store.getters.getUser) {
      console.log("用户信息id：" + this.$store.getters.getUser.id)
      this.user.id = this.$store.getters.getUser.id
      this.user.username = this.$store.getters.getUser.username
      this.user.avatar = this.$store.getters.getUser.avatar
      this.user.email = this.$store.getters.getUser.email

      this.hasLogin = true

    }
    this.$axios.get('/user').then(res => {
      this.allUserInfo = res.data.data
    })
  }
}
</script>

<style scoped>

.m-content {
  text-align: center;
  margin-top: 25px;
  min-height: 100vh;
}
</style>
